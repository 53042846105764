import './Features.css';
import build from '../img/AwM-gif1.gif';
import design from '../img/AwM-gif2.gif';
import arrange from '../img/AwM-gif4.gif';
import sell from '../img/AwM-gif3.gif';
import complete from '../img/AwM-gif5.gif';
import pull from '../img/AM-1.6.png';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';


function ButtonFeatures() {
    return (
        <Link to='/features'>
            <button>
                All features
            </button>
        </Link>
    );
}


const accordion = [ 
    {
        name: "Build",
        information: "your custom online entry form",
        content: build
    },
    {
        name: "Design",
        information: "your scoring process from scratch or using our templates",
        content: design
    },
    {
        name: "Arrange",
        information: "your presentation schedule",
        content: arrange
    },
    {
        name: "Sell",
        information: "tickets for the Awards ceremony",
        content: sell
    },
    {
        name: "Complete",
        information: "seating plans",
        content: complete
    },
    {   
        name: "Pull",
        information: "benchmarked reports for all your entrants whether they winners or ﬁnalists",
        content: pull
    }
];


    function Accordion() {

        const [active, setActive] = useState(0);

        function toggleAccordion(index) {
            setActive(index);
    }

    return (

        <div className='accordion'>
            {accordion.map((item, index) => 
            <div className='accordion-box' key={index} onClick={() => toggleAccordion(index)}>
                <div className={`accordion-screen ${active === index ? "active" : ""}`}>
                    <h3>{item.name}</h3>
                    <p>{item.information}</p>
                </div>
                <div className='accordion-info'>
                <div className={active === index ? "active" : "inactive"}>
                    <img src={item.content} alt=''/>
                </div>
                </div>
            </div>
            )}
        </div>

    );
}


function Features() {
   
    return (
        <div className="features">
            <div className="main-wrapper text-center">
                <h2>All your awards event features <br/> in one application</h2>
                <p>With AwardMost, you can create and manage multiple awards programs, invite nominations, and evaluate nominees with ease. Our user-friendly interface allows you to manage all aspects of the awards process in real-time, from setting up judging panels to announcing winners. With our powerful reporting tools, you can get insights into the success of your awards program and use this data to make informed decisions.</p>


                <Accordion />
                
                <p><b>Moreover, do all this in one place.</b></p>
                <ButtonFeatures />
            </div>
        </div>
    );
}

export default Features;