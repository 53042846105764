function GetDemoForm() {
    return (
        <div id="form" className="contact-us">
        <div className="contact-us-form text-center">
        <form action='https://forms.zohopublic.com/awardsinternational/form/AwardmostGetaDemo/formperma/3XoPY3CUsFek2MllXmFtgTD8mWJRgSFdaZhSpakO3Kw/htmlRecords/submit'name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data'>
                
                <input type="hidden" name="zf_referrer_name" value="" />
                <input type="hidden" name="zf_redirect_url" value="" />
                <input type="hidden" name="zc_gad" value="" />
                <input type="text" maxlength="255" name="Name_First" fieldType='7' placeholder="First Name" required/>
                <br/>
                <input type="text" maxlength="255" name="Name_Last" fieldType='7' placeholder="Last Name" required/>
                <br/>
                <input type="text" maxlength="255" name="Email" fieldType='9' placeholder="Email" required/>
                <br/>
                <input  type="text" name="SingleLine"  fieldType='1' maxlength="255" placeholder="Company" required/>
                <br/>
                <button type="submit">Submit</button>
        </form>
        </div>
        </div>
    )
}


export default GetDemoForm;