import GetDemoForm from "../components/get-demo";


function Contact() {
    return (
        <div className="contact-us">
            <div className="main-wrapper text-center">
                <p>Choose AwardMost for an eﬃcient, seamless, and hassle-free awards management experience. Contact us today to learn more about how we can help you recognise excellence.</p>
                <GetDemoForm />
            </div>
        </div>
    );
}

export default Contact;