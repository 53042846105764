import ScrollToTop from "..";
import { Helmet } from "react-helmet";
import '../css/AwardsPlatform.css';
import img1 from '../img/why-choose.png'
import BookConsultations from "../components/book-consulations";
import entry from '../img/EntrySub-icon.png';
import event from '../img/Event-icon.png';
import scoring from '../img/Scoring-icon.png';



function HeadingButton() {

    const handleClickScroll = () => {
        const element = document.getElementById('book-consultations-form');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
            <button className="grow-button" onClick={handleClickScroll}>
                Grow my Awards
            </button>
    );
}

function SolutionsButton() {

    const handleClickScroll = () => {
        const element = document.getElementById('book-consultations-form');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
            <button onClick={handleClickScroll}>
                Choose solution
            </button>
    );
}

function BookButton() {

    const handleClickScroll = () => {
        const element = document.getElementById('book-consultations-form');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
            <button onClick={handleClickScroll}>
                Book consultations
            </button>
    );
}



function AwardsPlatfrom() {
    return (
        <>
        <Helmet>
            <title>AwardMost &nbsp; - &nbsp; Your Best Fitted Awards Management Platform </title>
            <meta name="description" content="" />
        </Helmet>
        <ScrollToTop />
            <div className="platform-back">
                <div className="main-wrapper">
                    <div className="platform-heading">
                        <div className="platform-heading-left">
                            <h1>Your Best Fitted Awards Management Platform</h1>
                            <p>Awards Management developers, designers and business experts using tailored solutions to make your awards programme grow. Let's transform the way you manage your awards, making the process smoother, more eﬃcient, and more successful.</p>
                        </div>
                        <div className="platform-heading-right">
                            <p><b>Tell us a bit about your project <br/> and we will propose the best solution</b></p>
                            <HeadingButton/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="choose-solutions">
                <div className="main-wrapper">
                    <div className="solutions-boxes" style={{textAlign:'center'}}>
                        <div className="solutions-box">
                            <img alt="" src={entry}/>
                            <p><b>Entry Submission</b></p>
                            <p>Simplify the submission process making it easy for participants to enter their nominations</p>
                        </div>
                        <div className="solutions-box">
                        <img alt="" src={scoring}/>
                            <p><b>Scoring Perfection</b></p>
                            <p>Craft a ﬂawless scoring system from shortlisting to ﬁnals, ensuring error-free multiple rounds of eliminations</p>
                        </div>
                        <div className="solutions-box">
                        <img alt="" src={event}/>
                            <p><b>Event Planning</b></p>
                            <p>Eﬃciently plan and organize your events from start to ﬁnish with our intuitive event planning tools</p>
                        </div>
                    </div>
                    <div className="solutions-button">
                        <SolutionsButton/>
                    </div>
                </div>
            </div>
            <div className="why-choose">
                <div className="main-wrapper">
                    <div className="why-choose-content">
                        <h2>Why Choose AwardMost</h2>

                        <div className="why-choose-box">
                            <div className="why-choose-box-left">
                                <img src={img1} alt=''/>
                            </div>
                            <div className="why-choose-box-right">
                                <p><b>Recognized Industry Leader</b></p>
                                <p>Join some of the greatest awards companies who run their business awards on our platform.</p>

                                <p><b>User-Friendly Interface</b></p>
                                <p>Our user-centric approach ensures a smooth and intuitive experience for awards managers and participants.</p>

                                <p><b>Flexible and Scalable</b></p>
                                <p>Whether you're planning a small business event or a large-scale industry awards, our software is ﬂexible and scalable to meet your needs.</p>

                                <p><b>Unmatched Customer Service</b></p>
                                <p>Our dedicated support team is here to assist you at every step of your awards project.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="solutions-back">
                <div className="main-wrapper">
                    <div className="solutions-pricing-content">
                        <h2>Select Your Tailored Solution <br/> to Grow Your Awards Programme</h2>


                        <div className="pricing">

                                <div className="pricing-boxes">
                                    <div className="pricing-box">
                                        <div className="pricing-heading">
                                            <h2>Starter</h2>
                                            <p>Best for SMEs and companies who run simple awards programmes</p>
                                        </div>
                                        <div className="pricing-info">
                                        <p>From <br/> <span>&pound;1500</span></p>
                                            <ul>
                                                <li>1 Event</li>
                                                <li>Unlimited categories</li>
                                                <li>100 Registrations</li>
                                                <li>1 Manager</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="pricing-box">
                                        <div className="pricing-heading third-green-back">
                                            <h2>Premium</h2>
                                            <p>Best for sophisticated awards programmes with multiple scoring rounds and paid entries</p>
                                        </div>
                                        <div className="pricing-info third-green-back">
                                        <p>From <br/> <span>&pound;3500</span></p>
                                            <ul>
                                                <li>1 Event</li>
                                                <li>Unlimited categories</li>
                                                <li>1000 Registrations</li>
                                                <li>5 Manager</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="pricing-box">
                                        <div className="pricing-heading">
                                            <h2>Enterprise</h2>
                                            <p>Fully branded with high volume of registrations and communities</p>
                                        </div>
                                        <div className="pricing-info">
                                            <p><br/> <span>Talk to sales</span></p>
                                            <ul>
                                                <li>Unlimited Events</li>
                                                <li>Unlimited Categories</li>
                                                <li>Unlimited Registrations</li>
                                                <li>Unlimited Managers</li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pricing-button">
                    <BookButton/>
                </div>
            </div>
            <div id="book-consultations-form" className="book-consultations-section">
                <div className="main-wrapper">
                    <p>Are you looking for the best awards management software</p>
                    <p><b>Submit your query and book a free consultations</b></p>
                    <p>We will get in touch in less than 24 hours with the best solution for your awards programme.</p>

                    <BookConsultations/>
                </div>
            </div>
        </>
    )
}

export default AwardsPlatfrom;