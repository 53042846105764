import imgBox1 from '../img/box1.png';
import imgBox2 from '../img/box2.png';
import imgBox3 from '../img/box3.png';
import { Link } from 'react-router-dom';

function CasesBox(box) {
    return (
        <div className="cases-box">
            <img src={box.image} alt="" />
            <h3>{box.heading}</h3>
            <p>{box.info}</p>
            <Link to={box.link}>Read case study</Link>
        </div>
    );
}

function Cases() {
    return (
        <div className="cases">
            <div className="main-wrapper">
                <h2 className="text-center">Case Studies</h2>
                <div className="cases-boxes">
                    <CasesBox 
                    image = {imgBox1}
                    heading = "UK Customer Experience Awards (Live, Hybrid)"
                    info = "Hybrid awards. Live online presentations with the live in-venue awards ceremony held at Wembley Stadium in London, UK."
                    link = "/cxa-case-study"
                    />

                    <CasesBox 
                    image = {imgBox2}
                    heading = "UK Business Awards (Online)"
                    info = "Smart awards. Quick and simple awards programme with one scoring round best suited for SMEs."
                    link = "/ba-case-study"
                    />

                    <CasesBox 
                    image = {imgBox3}
                    heading = "International Customer Experience Awards (Online, Hybrid)"
                    info = "Full online awards. From start to ﬁnish held live online. The awards ceremony broadcasted from a TV studio."
                    link = "/icxa-case-study"
                    />
                </div>
            </div>
        </div>
    );
}

export default Cases;