import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './fonts/Gilroy/FontsFree-Net-Gilroy-ExtraBold.ttf';
import './fonts/Proxima-Nova/FontsFree-Net-proxima_nova_reg-webfont.ttf';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Menu from './components/Menu';
import Footer from './components/Footer';
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function ScrollToTop() {
  useEffect(() => {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
}

export default ScrollToTop;

const root = ReactDOM.createRoot(document.getElementById('root'));




 root.render(
   <React.StrictMode>
    <BrowserRouter>
      <Helmet>
        <title>AwardMost</title>
        <meta name="description" content="The leading awards management software designed to simplify the entire awards process, from nomination to recognition." />
      </Helmet>
      <ScrollToTop />
      <Menu />
      <App />
      <Footer />
     </BrowserRouter>
   </React.StrictMode>
 );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();