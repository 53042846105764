import { FaEnvelope, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "./Modal";


function Button() {
    const [isOpen, setIsOpen] = useState(false);

      return (
        <>
          <button onClick={() => setIsOpen(true)}>
              Get a demo       
          </button>
        {isOpen && <Modal setIsOpen={setIsOpen} />}
        </>
      );
}


function Footer() {
     const menuList = ["Solutions", "Features", "Pricing", "Case Study", "Login", "Blog"];
    return (
       <footer>
            <div className="main-wrapper">
                <div className="footer">

                <div className='menu-items'>
                    {/* <h2>Contact us</h2>
                    <p>Choose AwardMost for an efficient, seamless, and hasle-free awards management experience. Contact us today to learn more about how we can help you recognise excellence.</p> */}
                     <ul>
                        <li><Link to="/solutions">{menuList[0]}</Link></li>
                        <li><Link to="/features">{menuList[1]}</Link></li>
                        <li><Link to="/pricing">{menuList[2]}</Link></li>
                        <li><Link to="/cxa-case-study">{menuList[3]}</Link></li>
                        <li><Link to="/blog">{menuList[5]}</Link></li>
                        <li><Link to="https://playground.awardmost.com/login" target="_blank">{menuList[4]}</Link></li> 
                    </ul>
                </div>   

                <div className='menu-button'>
                    <Button />

                    <div className="social-icons">
                
                        <Link to="mailto:info@awardmost.com"> <FaEnvelope /> </Link> <Link to="https://www.linkedin.com/company/awardmost/" target="_blank"> <FaLinkedinIn /> </Link> <Link to="https://twitter.com/award_most" target="_blank"> <FaTwitter /> </Link> <Link to="https://www.youtube.com/@AwardMost/" target="_blank"> <FaYoutube /> </Link> <br/>

                        <Link to="https://tree-nation.com/profile/awardmost" target="_blank" style={{position:'relative', cursor:'pointer', display:'block',  zIndex: 999}}>
                        <img src="https://tree-nation.com/images/tracking/label-co2-website-white-en.png" style={{width: '157px', height:'auto', marginTop: '20px'}} />
                        </Link>

                        <script src="https://tree-nation.com/js/track.js"></script>
                        <script>treenation_track("64ae60fa46ddf");</script>

                    </div>
                </div>
            </div>

            


            <div className="company-part">
                <div>
                <p>
                <Link to='/terms-and-conditions'>
                    Terms and Conditions
                </Link> &nbsp;
                <Link to='/privacy-policy'>
                    Privacy Policy
                </Link>
                </p>
                </div>
                <div>
                <p>
                    <span style={{color: "#fff"}}><b>Most International ltd</b></span>  <br/>
                    Registered office address <br/>
                    International House, 36-38 Cornhill, London, EC3V 3NG <br/>
                </p>
                </div>
            </div>

            </div>

        </footer> 
    );
}

export default Footer;