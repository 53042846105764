function BookConsultations() {

    return (
        <div id="form-1" className="contact-us">
        <div className="contact-us-form text-center">
        <form action='https://forms.zohopublic.com/awardsinternational/form/AwardmostBookaConsultation/formperma/Qv4firh2L7pSw2Q3_LeLauXFU1e0mBRP1D77BCY3AqE/htmlRecords/submit'name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data'>
                
                <input type="hidden" name="zf_referrer_name" value="" />
                <input type="hidden" name="zf_redirect_url" value="" />
                <input type="hidden" name="zc_gad" value="" />
                <input type="text" maxlength="255" name="Name_First" fieldType='7' placeholder="First Name*" required/>
                <br/>
                <input type="text" maxlength="255" name="Name_Last" fieldType='7' placeholder="Last Name*" required/>
                <br/>
                <input type="text" maxlength="255" name="Email" fieldType='9' placeholder="Email*" required/>
                <br/>
                <input  type="text" name="SingleLine"  fieldType='1' maxlength="255" placeholder="Company*" required/>
                <br/>
                <input  type="text" name="PhoneNumber_countrycode"  fieldType='11' maxlength="255" placeholder="Phone*" required/>
                <br/>
                <select name="Dropdown" required>
                    <option selected="true" value="Programme scope">Programme scope*</option>
                    <option value="Up&#x20;to&#x20;100&#x20;submissions&#x20;with&#x20;one&#x20;scoring&#x20;round">Up to 100 submissions with one scoring round</option>
                    <option value="Large&#x20;scale&#x20;awards&#x20;programme&#x20;with&#x20;multiple&#x20;scoring&#x20;rounds">Large scale awards programme with multiple scoring rounds</option>
                    <option value="Custom&#x20;awards&#x20;programme&#x20;with&#x20;multiple&#x20;evaluation&#x20;rounds">Custom awards programme with multiple evaluation rounds</option>
                </select>
                <br/>
                <select name="Dropdown1" required>
                    <option selected="true" value="Programme type">Programme type*</option>
                    <option value="Online">Online</option>
                    <option value="Hybrid">Hybrid</option>
                    <option value="Live">Live</option>
                </select>
                <div class="g-recaptcha" style={{width: "45%", margin: "0 auto"}} data-sitekey="6LeqRLQpAAAAAHtGBKxBTudMrLM0gdQaBeZ_PHBf" data-action="LOGIN"></div>
                <br/>
                <button type="submit">Submit</button>
        </form>
        </div>
        </div>
    )
}


export default BookConsultations;