import '../css/Blog.css';
import blog1 from '../img/blog1.png';
import blog2 from '../img/blog2.png';
import blog3 from '../img/blog3.png';
import blog4 from '../img/blog4.png';
import { Link } from 'react-router-dom';
import ScrollToTop from '..';
import { Helmet } from 'react-helmet';


function BlogList(blog) {
    return (
            <div className='blog-box'>
                <div className='blog-image'>
                    <img src={blog.img} alt='' />
                </div>
                <div className='blog-info'>
                    <h3>{blog.heading}</h3>
                    <p>{blog.description}</p>
                </div>
            </div>
    )
}



function Blog() {
    return (
        <>
        <Helmet>
            <title>AwardMost &nbsp; - &nbsp; Blog </title>
            <meta name="description" content="" />
        </Helmet>
        <ScrollToTop />
        <div className="blog">
            <div className="main-wrapper">
                <div className="page-heading">
                    <h1 className="text-center">Blog</h1>
                </div>

                <div className='blog-lists'>
                    <Link to="/blog/building-an-effective-entry-form-7-steps-for-success">
                    <BlogList img={blog4} heading="Building an Effective Entry Form: 7 Steps for Success" description="The entry form is the gateway to a successful awards programme, and creating a user-friendly form is crucial for maximising participation and streamlining the submission process. In this article, we will outline key steps to help you build an effective entry form that ensures a seamless experience for participants."/>
                    </Link>

                    <Link to="/blog/maximizing-the-roi-on-entering-an-awards-programme">
                    <BlogList img={blog3} heading="Maximizing the ROI on Entering an Awards Programme" description="Participating in an awards programme goes beyond the pursuit of recognition; it is a strategic investment with the potential for significant returns. In this article, we will explore the various ways in which entering an awards programme can deliver substantial value and maximize your return on investment (ROI)."/>
                    </Link>

                    <Link to="/blog/how-to-provide-extra-value-to-your-judges">
                    <BlogList img={blog2} heading="How to Provide Extra Value to Your Judges: Elevating the Awards Program Experience" description="Engaging and retaining high-quality judges is essential for the success of any awards program. To ensure their continued participation and commitment, it's important to go beyond the basic requirements and provide extra value to your judges."/>
                    </Link>

                    <Link to="/blog/guide-to-an-effective-awards-programme">
                    <BlogList img={blog1} heading="Guide to an Effective Awards Programme" description="Awards programmes play a pivotal role in recognising and celebrating excellence across various industries. Whether you're organizing an awards programme for your company, industry, or community, ensuring its effectiveness is essential. From defining your objectives to managing the submission process and delivering a memorable event, let's dive into the world of crafting an effective awards programme."/>
                    </Link>
                </div>
            </div>

        </div>
        </>
    )
}


export default Blog;