import imgModels from '../img/models.png';

function ModelsInfo(props) {
    return (
        <div className='models-box'>
            <h3>{props.heading}</h3>
            <p>{props.info}</p>
        </div>
    ); 
}

// function LeftSide() {
//     return (
//         <>
//             <div className="left-shape-one"></div>
//             <div className="left-shape-two"></div>
//         </>
//     );
// }

// function RightSide() {
//     return (
//         <>
//             <div className="right-shape-two"></div>
//             <div className="right-shape-one"></div>
//         </>
//     );
// }

function AwardModels() {
    return (
        <div className="award-models">
        <div className="main-wrapper text-center">
            <h2>Live, online and hybrid</h2>
            <p>AwardMost offers dynamic and immersive experience for organisers and attendees alike. Seamlessly bringing people together from across the globe, our platform offers a robust set of features to ensure a successful and engaging live, online or hybrid event.</p>
        </div>

        <div className="shapes">
            <img src={imgModels} alt="" />
        </div>

    
        <div className='main-wrapper'>
            <div className='models-info'>
                
                {/* <ModelsInfo heading="Live" info="Seamlessly bringing people together from across the globe, our platform offers a robust set of features to ensure a successful and engaging live, online or hybrid event."/>

                <ModelsInfo heading="Online" info="Seamlessly bringing people together from across the globe, our platform offers a robust set of features to ensure a successful and engaging live, online or hybrid event."/>

                <ModelsInfo heading="Hybrid" info="Seamlessly bringing people together from across the globe, our platform offers a robust set of features to ensure a successful and engaging live, online or hybrid event."/> */}

            </div>
        </div>

        
        </div>
    );
}

export default AwardModels;