import UseCasesDownload from "../components/UseCasesDownload";
import cxa from '../img/ukcxa-case-study-cover.png';
import '../css/UseCases.css';
import ScrollToTop from "..";
import { Helmet } from "react-helmet";

function CxaCaseStudy() {
    return (
        <>
        <Helmet>
            <title>AwardMost &nbsp; - &nbsp; CXA Case Study </title>
            <meta name="description" content="" />
        </Helmet>
        <ScrollToTop/>
        <div className="use-cases-page">
        <div className="main-wrapper">
            <h1 className="text-center">Case study</h1>
        

        
        <UseCasesDownload 
            image = {cxa}
            heading = "UK Customer Experience Awards"
            info = "Hybrid awards. Live online presentations with the live in-venue awards ceremony held at Wembley Stadium in London, UK."
            action = "https://forms.zohopublic.com/awardsinternational/form/AwardmostCaseStudy/formperma/7ojWWVB0E8higdKO9o0GXzBMe7UPdJnVGaeyNkB5hSE/htmlRecords/submit"
        />
        </div>
        </div>
        </>
    )
}


export default CxaCaseStudy;