import '../css/Menu.css';
import awardLogo from '../AwardMostlogo.png';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import { useState, useRef } from 'react';
import { useOnHoverOutside } from './useOnHoverOutside';
import DropDownMenu from './DropDownMenu';
import { FaBars, FaTimes } from 'react-icons/fa';

function Button() {
    const [isOpen, setIsOpen] = useState(false);

      return (
        <>
          <button onClick={() => setIsOpen(true)}>  
              Get a demo       
          </button>
        {isOpen && <Modal setIsOpen={setIsOpen} />}
        </>
      );
}

function Menu() {

  const dropdownRef = useRef(null); // Create a reference for dropdown container
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);

   // Function to close dropdown
   const closeHoverMenu = () => {
     setMenuDropDownOpen(false);
   };

  useOnHoverOutside(dropdownRef, closeHoverMenu); // Call the hook


  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle('responsive_nav');
  }
  const hideNavbar =()=>{
    navRef.current.classList.remove("responsive_nav")
  }

  //   const [hamburgerOpen, setHamburgerOpen] = useState(false);

  //   const toggleHamburger = () =>{
  //       setHamburgerOpen(!hamburgerOpen)
  //   }

    return (
      <>
        <header>
        <div className='menu-logo'>
            <Link to="/">
                <img src={awardLogo} alt='logo'/>
            </Link>
        </div>

        <nav id='menu' ref={navRef}>        
        <div className='menu-items'>
        
             <ul> 
                 <li><Link onClick={hideNavbar} to="/solutions">Solutions</Link></li>
                 <li><Link onClick={hideNavbar} to="/features">Features</Link></li>
                 <li><Link onClick={hideNavbar} to="/pricing">Pricing</Link></li>
                 <li onClick={hideNavbar} onMouseOver={() => setMenuDropDownOpen(true)} ref={dropdownRef}><Link to="#">Case Study</Link>
                 {isMenuDropDownOpen && <DropDownMenu />} 
                 </li>
                 <li><Link onClick={hideNavbar} to="/blog">Blog</Link></li>
                 <li><Link onClick={hideNavbar} to="https://playground.awardmost.com/login" target='_blank'>Login</Link></li>
             </ul>

            <div className='menu-button'>
              <Button />
            </div>

            <button className='nav-btn nav-close-btn' onClick={showNavbar}>
              <FaTimes/>
            </button>

         </div>
         </nav>
        
         <button className='nav-btn' onClick={showNavbar}>
            <FaBars/>
         </button>
   
         </header>
</>
    );
}

export default Menu;