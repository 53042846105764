import './Testimonials.css';
import neil from '../img/Neil_AwM.png'
import gary from '../img/Gary_AwM.png'
import valerie from '../img/Valerie_AwM.png'
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'react-router-dom';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";
import Modal from '../components/Modal';



function Button() {
    const [isOpen, setIsOpen] = useState(false);

      return (
        <>
          <button onClick={() => setIsOpen(true)}>
              Get a demo       
          </button>
        {isOpen && <Modal setIsOpen={setIsOpen} />}
        </>
      );
}

function Slider() {

    const testimonials = [
        {
            info: "'We trust AwardMost for our awards management needs because they prioritise the security of our customer data. With their strict data protection protocols, we have peace of mind knowing our information is safe and confidential. Highly recommended!'",
            name: "Neil Skehel, CEO, Awards International",
            image: neil
        },
        {
            info: "'AwardMost is incredibly user-friendly, easily accessible and allows me to review and score entries with ease.  As a Head Judge, with a busy day job, it has transformed my awards experience.'",
            name: "Gary Jackson, Group Director of Communications & Customer Success for Mears Group",
            image: gary
        },
        {
            info: "'The new AwardMost's functionality is great for judges! It is obvious that the team has put a lot of work into simplifying the workflow, from navigating around the entries to reviewing supporting materials and submitting scores.'",
            name: "Valery Howe, Director, Global Marketing & Customer Intelligence",
            image: valerie
        }
    ];

    const [current, setCurrent] = useState(0);

    // function nextSlide() {
    //     const lastSlide = setCurrent === current.length - 1;
    //     const newIndex = lastSlide ? 0 : setCurrent +  1; 
    //     setCurrent(newIndex)
    // };

    // function prevSlide() {
    //     const firstSlide = setCurrent === 0;
    //     const newIndex = firstSlide ? current.length - 1 : setCurrent - 1; 
    //     setCurrent(newIndex)
    // };

    function goToSlide(slideIndex) {
        setCurrent(slideIndex);
    }

    return (
        <div className='testimonials-slider'>
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
    
        {testimonials.map((other, index) => 
        <SwiperSlide>
        <div className='testimonials-box' key={index}>
            <div className='testimonials-half'>
                <div className='testimonials-info'>
                    <p>{other.info}</p>
                    <p>{other.name}</p>
                </div>
                <div className='testimonials-image'>
                    <img src={other.image} alt=''/>
                </div>
            </div>
        </div>
        </SwiperSlide>
        )}
        </Swiper>
        </div>
    );
}

function Testimonials() {
    return (
        <div className="testimonials">
            <div className="main-wrapper text-center">
                <h2>Trusted and loved software</h2>
                <p>AwardMost is a cloud-based software, which means you can access it from anywhere, at any time, on any device. Our platform is secure and reliable, so you can trust that your data is safe with us.</p>

                <Slider />

                <br/><br/>

                <Button />
            </div>
        </div>
    );
}   

export default Testimonials;