import "../css/Solutions.css";
import img1 from '../img/solutions-1.png'
import img2 from '../img/solutions-2.png'
import ScrollToTop from "..";
import conferenceIcon from '../img/Conferences-icon.png';
import smartIcon from '../img/Smart-awards-icon.png';
import industryIcon from '../img/Industry-awards-icon.png';
import employeeIcon from '../img/Employee-recognition-icon.png';
import { useState } from "react";
import Modal from "../components/Modal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FaTimes } from 'react-icons/fa';

function Button() {
    const [isOpen, setIsOpen] = useState(false);

      return (
        <>
          <button onClick={() => setIsOpen(true)}>
              Get a demo       
          </button>
        {isOpen && <Modal setIsOpen={setIsOpen} />}
        </>
      );
}


// function SolutionsBoxes(props) {
//     return (
//         <div className="experience-box text-center">
//             <img src={props.image} alt=""/>
//             <h3>{props.title}</h3>
//             <h3>{props.info}</h3>        
//         </div>
//     )
// }

const SolutionsBoxes = [ 
    {
        image: smartIcon,
        heading: "Smart awards",
        content: "(Fully online)",
        description: (
            <>
            <p>This is an innovative solution tailored for SMEs and startups. With our user-friendly interface and streamlined functionality, Smart Awards template simplifies the submission and scoring process. Efficiency of a single scoring round, allows for faster decision-making and reduces time investment.</p>
            <p>Our software also offers customizable criteria, ensuring that awards are judged based on your specific requirements. Stay ahead of the competition and recognize excellence effortlessly with Smart Awards solution.</p>
            <p><i><b>This solution rose from the successful years of the UK Business Awards which you can read the case study about.</b></i></p>
            <div className="solutions-buttons">
                <Link to="/ba-case-study">Read case study</Link>
                <Link to='/pricing'>
                    <button>
                        Talk to sales
                    </button>
                </Link>
            </div>
            </>
        ) 
    },
    {
        image: industryIcon,
        heading: "Industry awards",
        content: "(Live, hybrid, online)",
        description: (
            <>
            <p>This versatile solution is designed to empower your organization's awards program whether you're hosting a regional ceremony or a global recognition event. Industry Awards solution scales to meet your specific needs.</p>
            <p>With the ability to conduct multiple scoring rounds, you can ensure thorough evaluation and fair judgment for all submissions. Seamlessly manage presentation schedules to create an engaging and memorable experience for attendees.</p>
            <p>Additionally, this solution simplifies the complex task of arranging seating plans, making it effortless to accommodate guests and optimize networking opportunities. Elevate your awards program to new heights with Industry Awards solution and deliver an exceptional event that celebrates excellence in your industry.</p>
            <p><i><b>Read the case study about the UK Customer Experience Awards programme the first the original and the biggest CX Awards in the UK.</b></i></p>
            <div className="solutions-buttons">
                <Link to="/cxa-case-study">Read case study</Link>
                <Link to='/pricing'>
                    <button>
                        Talk to sales
                    </button>
                </Link>
            </div>
            </>
        )
    },
    {
        image: employeeIcon,
        heading: "Employee recognition programs",
        content: "",
        description: (
            <>
            <p>If your plan is to acknowledge and appreciate your valuable workforce, with our intuitive platform, you can effortlessly implement and manage robust recognition initiatives that foster a positive and motivated work environment. </p>
            <p>Our software offers a diverse range of customizable features, allowing you to tailor recognition programs to align with your company values and culture. From peer-to-peer recognition and manager-led commendations to milestone celebrations and performance-based rewards, our software empowers organizations to inspire and motivate employees at every level.</p>
            <p>Nurture a culture of appreciation, boost employee morale, and drive productivity with our cutting-edge software that puts the power of recognition in your hands.</p>
           
            <div className="solutions-buttons">
                <Link to='/pricing'>
                    <button>
                        Talk to sales
                    </button>
                </Link>
            </div>
            </>
        )
    },
    {
        image: conferenceIcon,
        heading: "Business events/Conferences",
        content: "(Live, hybrid, online)",
        description: (
            <>
            <p>In addition to its powerful awards management capabilities, our awards software goes beyond recognition programs to offer robust features for organizing business events and conferences.</p>
            <p>With our software, you can seamlessly plan, manage, and execute successful events that leave a lasting impression on attendees. From streamlined registration processes and attendee management to creating customizable event agendas and session schedules, our software provides the tools to ensure a seamless and engaging experience for all participants. </p>
            <p>With integrated communication features, you can easily send event updates, reminders, and notifications to keep attendees informed.</p>
            <p><i><b>Take your business events and conferences to the next level with our award software's comprehensive event management features, and deliver exceptional experiences that leave a lasting impact.</b></i></p>
            <div className="solutions-buttons">
                <Link to='/pricing'>
                    <button>
                        Talk to sales
                    </button>
                </Link>
            </div>
            </>
        )
    }
];


function SolutionsAccordion() {

    const [active, setActive] = useState(0);

    function toggleAccordion(index) {
        setActive(index);
}

return (
    <div className='solutions-accordion'>
        <div className="solutions-boxes">
        {SolutionsBoxes.map((item, index) => 
        <div className={`solutions-accordion-screen ${active === index ? "active" : ""}`} key={index} onClick={() => toggleAccordion(index)}>
                <img src={item.image} alt="" />
                <h3>{item.heading}</h3>
                <h3>{item.content}</h3>
        </div>
        )}
        </div>
        
        <>
        {SolutionsBoxes.map((item, index) => 
        <div className='solutions-accordion-info' key={index}>
            <div className={active === index ? "active" : "inactive"}>
            <div className="close-btn" onClick={() => toggleAccordion(null)}><FaTimes /></div>
            <p>{item.description}</p>
            </div>
        </div>
        )}
        </>
        
    </div>
);
}


function Solutions() {
    return (
        <>
        <Helmet>
            <title>AwardMost &nbsp; - &nbsp; Solutions </title>
            <meta name="description" content="" />
        </Helmet>
        <ScrollToTop />
        <div className="solutions">
                <div className="main-wrapper solutions-first-screen">
                    <div className="solutions-first-screen-left">
                        <h1>Solutions</h1>
                        <p>Awards Programmes</p>

                        <p><b>Experience turned into software</b></p>
                        <p>At AwardMost, we have years of experience working with the leading business awards company, Awards International. Our extensive experience in managing successful awards programs and conferences has led us to create a software that can fit any type of awards program, conference or event.</p>
                        <br/>
                        <p><b>Fully customizable</b></p>
                        <p>We understand that each awards program is unique and has its own requirements, which is why our software is fully customizable. You can configure the software to match your specific needs, including nomination forms, judging criteria, scoring systems, and more.</p>
                    </div>
                    <div className="solutions-first-screen-right">
                        <img src={img1} alt="" />
                    </div>
                </div>

                <div className="main-wrapper solutions-first-screen">
                    <div className="solutions-first-screen-right">
                        <img src={img2} alt="" />
                    </div>
                    
                    <div className="solutions-first-screen-left">
                        <h1 style={{display: 'none'}}>&nbsp;</h1>
                        <p>Business Events</p>

                        <p><b>Plan with ease</b></p>
                        <p>Besides awards programs, our software can be used to manage conferences and business events. We also believe in the importance of creating a seamless and engaging experience for attendees, which is why our software includes features such as registration management, session scheduling and seat planning.</p>
                        <br/>
                        <p><b>Video streaming</b></p>
                        <p>From interactive live streaming capabilities and virtual networking opportunities to customizable virtual exhibit halls and breakout rooms, our platform empowers organisers to create an event that mirrors the richness and interactivity of in-person gatherings. Whether it's a conference, trade show, or virtual summit, our online event platform is the ideal solution to connect, inspire, and collaborate in the digital realm.</p>
                    </div>
                    
                </div>

        </div>


        <div className="discover">
            <div className="discover-content main-wrapper text-center">
                <h1>Discover a whole lot <br/> of AwardMost!</h1>
                <Button />
            </div>
        </div>


        <div className="solutions-tabs">
            <div className="main-wrapper text-center">
                <h2>Choose your suite</h2>
            </div>

            <div className="main-wrapper">
                <SolutionsAccordion />
            </div> 
        </div>



        </>
    )
}


export default Solutions;