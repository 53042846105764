import "../css/Pricing.css";
import GetDemoForm from "../components/get-demo";
import ScrollToTop from "..";
import { Helmet } from "react-helmet";
import { useState } from 'react';
import Modal from "../components/Modal";

function Button() {
    const [isOpen, setIsOpen] = useState(false);
    // onClick={() => setIsOpen(true)}
      return (
        <>
          <button onClick={() => setIsOpen(true)}>
              Get Started     
          </button>
        {isOpen && <Modal setIsOpen={setIsOpen} />}
        </>
      );
}

function Pricing() {

    const starterList = ["In app notifications", "Email notifications", "Vanity domain", "Free entries", "Autosave", "Edit entry forms", "Upload limit 1MB", "Simple scoring", "1 Scoring round", "Comments", "Download entry PDF", "Event reports"];

    const premiumList = ["Multiple program phases", "Paid entries", "Order management", "Configurable entry form", "Upload limit 5MB", "Configurable scoring form", "Unlimited scoring rounds", "Scoring options", "Product management", "Discounts", "Promo-codes", "Payment integrations", "Booking products", "Guest invitations", "Short agenda", "Presentation schedule planner"];

    const enterpriseList = ["Dedicated support", "Enterprise ts&Cs", "Networking", "Video stream", "Recording", "Screen share presentation", "1 on 1 meetings", "Sponsor sessions", "Benchmark reports", "CMS"];



    return (
        <>
        <Helmet>
            <title>AwardMost &nbsp; - &nbsp; Pricing </title>
            <meta name="description" content="" />
        </Helmet>
        <ScrollToTop />
        <div className="pricing">
            <div className="main-wrapper">
                <div className="page-heading">
                    <h1 className="text-center">Pricing</h1>
                    <p className="text-center">Get the most sales with any of the packages</p>
                </div>

                <div className="pricing-boxes">
                    <div className="pricing-box">
                        <div className="pricing-heading">
                            <h2>Starter</h2>
                            <p>Best for SMEs and companies who run simple awards programmes</p>
                        </div>
                        <div className="pricing-info">
                        <p>From <br/> <span>&pound;1500</span></p>
                            <ul>
                                <li>1 Event</li>
                                <li>Unlimited categories</li>
                                <li>100 Registrations</li>
                                <li>1 Manager</li>
                            </ul>
                            {/* <div className="divider"></div> */}
                            <div className="pricing-list">
                                <ul>
                                    {starterList.map((list, index) => 
                                    <li key={index}>{list}</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                         <div className="pricing-price">
                            <Button />
                        </div> 
                    </div>

                    <div className="pricing-box">
                        <div className="pricing-heading third-green-back">
                            <h2>Premium</h2>
                            <p>Best for sophisticated awards programmes with multiple scoring rounds and paid entries</p>
                        </div>
                        <div className="pricing-info third-green-back">
                        <p>From <br/> <span>&pound;3500</span></p>
                            <ul>
                                <li>1 Event</li>
                                <li>Unlimited categories</li>
                                <li>1000 Registrations</li>
                                <li>5 Manager</li>
                            </ul>
                            {/* <div className="divider"></div> */}
                            <div className="pricing-list">
                            <p>Everything in Starter +</p>
                                <ul>
                                    {premiumList.map((list1, index) => 
                                    <li key={index}>{list1}</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="pricing-price third-green-back">
                            <Button />
                        </div>
                    </div>

                    <div className="pricing-box">
                        <div className="pricing-heading">
                            <h2>Enterprise</h2>
                            <p>Fully branded with high volume of registrations and communities</p>
                        </div>
                        <div className="pricing-info">
                            <p><br/> <span>Talk to sales</span></p>
                            <ul>
                                <li>Unlimited Events</li>
                                <li>Unlimited Categories</li>
                                <li>Unlimited Registrations</li>
                                <li>Unlimited Managers</li>
                            </ul>
                            {/* <div className="divider"></div> */}
                            <div className="pricing-list">
                                <p>Everything in premium +</p>
                                <ul>
                                    {enterpriseList.map((list2, index) => 
                                    <li key={index}>{list2}</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="pricing-price">
                            <Button />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="pricing-form" style={{backgroundColor: "#e9f9f0", paddingTop: "50px"}}>
            <div className="main-wrapper">
                <p className="text-center">Contact us</p>
                <GetDemoForm />
            </div>
        </div>

        </>
    )
}


export default Pricing;