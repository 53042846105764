import conferenceIcon from '../img/Conferences-icon.png';
import smartIcon from '../img/Smart-awards-icon.png';
import industryIcon from '../img/Industry-awards-icon.png';
import employeeIcon from '../img/Employee-recognition-icon.png';
import arrow from '../img/arrow.png';
import { Link } from 'react-router-dom';
 

function ButtonMore() {
    return (
        <Link to='/solutions'>
            <button>
                Read More
            </button>
        </Link>
    );
}

function ExperienceBoxes(props) {
    return (
        <div className="experience-box">
            <img src={props.image} alt=""/>
            <h3>{props.title}</h3>
            {/* <img src={arrow} alt='' />         */}
        </div>
    )
}

function Experience() {
    return (
        <div className="experience">
            <div className="main-wrapper text-center">
                <h2>Our expertise turned into your <br/> awards experience</h2>
                <p>Our software is designed to be ﬂexible and customizable, so it can be tailored to meet your unique needs. Whether you're managing a large-scale industry awards program or a small, internal recognition scheme, AwardMost can be conﬁgured to ﬁt your requirements.</p>

                <div className='experience-boxes'>
                    <ExperienceBoxes image={smartIcon} title="Smart awards"/>
                    <ExperienceBoxes image={industryIcon} title="Industry awards"/>
                    <ExperienceBoxes image={employeeIcon} title="Employee recognition programs"/>
                    <ExperienceBoxes image={conferenceIcon} title="Business events/Conferences"/>
                </div>

                <ButtonMore />
            </div>
        </div>
    );
}

export default Experience;