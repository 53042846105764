import './Welcome.css';
import { useState } from 'react';
import Modal from "../components/Modal";
import { Link } from 'react-router-dom';

function Button() {
    const [isOpen, setIsOpen] = useState(false);
    // onClick={() => setIsOpen(true)}
      return (
        <>
          <button onClick={() => setIsOpen(true)}>
              Get a demo       
          </button>
        {isOpen && <Modal setIsOpen={setIsOpen} />}
        </>
      );
}
    
function WelcomeScreen() {
    return (
        <div className='top-back-image'>
            <div className='main-wrapper'>
                <div className='welcome-info'>
                    <h1>Get the most <br/> from your awards</h1>
                    <h2>Submit. Score. Celebrate.</h2>
                    <p>Welcome to AwardMost - the leading awards management software designed to simplify the entire awards process, from nomination to recognition. Our software streamlines every aspect of award management, making it easy for you to manage all your awards and nominees with just a few clicks.</p>
                    <Button />
                </div>
            </div>
        </div>
    );
}

export default WelcomeScreen;