import "../css/Features.css";
import drag from '../img/AwM-gif1.gif';
import editor from '../img/features-text-editor.png';
import validation from '../img/features-fine-tune-fields.png';
import discount from '../img/features-discount.png';
import scoring from '../img/judges-1.png';
import table from '../img/cxa-table.png';
import overview from '../img/AM-1.2.png';
import seating from '../img/AM-1.5.png';
import benchmark from '../img/AM-1.6.png';
import virtual from '../img/AM-chat.png';
import tv1 from '../img/AwM-1.png';
import tv2 from '../img/AwM-2.png';
import { useState } from "react";
import ScrollToTop from "..";
import { Helmet } from "react-helmet";
import { FaTimes } from 'react-icons/fa';


const accordion = [ 
    {
        name: "Entry form",
        content: (
        <>
        <p>Say goodbye to tedious manual entry processes and embrace the efficiency and accuracy of our customizable entry form builder.</p> 
        <p><b>Complete control</b></p>
        <p>Our aim is to put you in complete control of designing and creating entry forms tailored to your specific needs.</p>
       
        <p><b>Drag-and-drop</b></p>
        <p>With a user-friendly interface and intuitive drag-and-drop functionality, building professional and engaging entry forms has never been easier.</p>
        <img src={drag} alt="" width='100%'/>
        <p><b>Supporting documents</b></p>
        <p>From basic contact details to specific criteria and supporting documents, you have the flexibility to define the fields and format that align with your unique requirements.</p>
        <img src={editor} alt="" width='100%'/>   
        <p><b>Validation</b></p>
        <p>Additionally, our form builder offers a range of customization options, including branding elements, conditional logic, and validation rules, ensuring a seamless and user-friendly experience for entrants. </p>
        <img src={validation} alt="" width='100%'/>
        </>
        )
    },
    {
        name: "Scoring process",
        content: (
            <>
            <p><b>Overview</b></p>
            <p>With our intuitive platform, you can easily design scoring frameworks that align with your award categories and desired outcomes.</p>
            <img src={overview} alt="" width='100%'/>
            <p><b>Scoring rounds</b></p>
            <p>Define multiple criteria and scoring ranges to accurately assess submissions based on the unique requirements of each category.</p>
            <p><b>Choose panels</b></p>
            <p>Flexible scoring, enabling multiple judges to evaluate entries independently, ensuring fairness and diversity in the decision-making process.</p>
            <img src={scoring} alt="" width='100%'/>
            </>
        )
    },
    {
        name: "Presentation schedule",
        content: (
            <>
            <p>Effortlessly organize and coordinate the sequence of award presentations, ensuring a smooth and engaging event for participants and attendees.</p>
            <p>Define the order easily and timing of each presentation, taking into account factors such as award categories, special guests, and performance interludes.</p>
            <p>You can also designate specific time slots for custom sessions like keynote speech, acceptance speeches, lunch and breaks.</p>
            <p>Streamline the process of creating a flawless presentation schedule with our awards management software, saving you time and ensuring a memorable awards event for all involved.</p>
            </>
        )
    },
    {
        name: "Virtual venue",
        content: (
            <>
            <p><b>Live online or hybrid</b></p>
            <p>Effortlessly integrate live video streaming into your awards program, allowing remote participants to join the event virtually and ensuring a wider reach for your audience.</p>
            <p><b>Built-in streaming functionality</b></p>
            <p>Our built-in streaming functionality allows you to broadcast open presentations, the awards ceremony, speeches, and other event highlights.</p>
            <img src={virtual} alt="" width='100%'/>
            <p><b>Reach maximum</b></p>
            <p>The hybrid event capabilities of our awards management software empower you to blend in seamlessly-person and virtual experiences, ensuring that your awards program reaches its maximum potential and engages a diverse audience, regardless of their location.</p>
            </>
        )
    },
    {
        name: "Awards TV",
        content: (
            <>
            <p><b>Video-on-demand</b></p>
            <p>Extend the engagement beyond the event itself with our revolutionary feature, Awards TV. With video-on-demand capabilities, you can keep your attendees captivated long after the event has concluded.</p>
            <p><b>Relive memorable moments</b></p>
            <p>Our platform allows you to effortlessly share recorded sessions with your awards participants, providing them with unlimited access for an extended period. This means they can revisit key presentations, relive memorable moments, and gain valuable insights at their convenience.</p>
            <p><b>Enhance overall experience</b></p>
            <p>By offering on-demand content, you enhance the overall attendee experience and ensure that the knowledge and inspiration gained from your event continue to resonate long after its conclusion.</p>
            <img src={tv1} alt="" width='100%'/>
            <img src={tv2} alt="" width='100%'/>
            </>
        )
    },
    {   
        name: "Tickets",
        content: (
            <>
            <p><b>Maximize your ticket sales and optimize revenue generation</b></p>
            <p>Our platform empowers you to offer a range of ticketing options, including premium and standard seats, tables, or similar bundles, tailored to meet the preferences of your attendees. </p>
            <p><b>Enticing offers</b></p>
            <p>Create exclusive premium seating sections with enhanced benefits and amenities, enticing those seeking an elevated experience.</p>
            <img src={table} alt="" width='100%'/>
            <p><b>Discount options</b></p>
            <p>Additionally, our software supports the implementation of discounts and promo-codes, allowing you to create targeted marketing campaigns and reward loyal attendees.</p>
            <img src={discount} alt="" width='100%'/>
            <p>By offering flexible ticketing options and incentives, you can effectively attract and engage a diverse range of participants, ultimately maximising your ticket sales and driving the success of your awards event.</p>
            </>
        )
    },
    {   
        name: "Event management",
        content: (
            <>
            <p><b>All your plans in one place</b></p>
            <p>Event management features allow organisers to plan effortlessly and organize the open presentations, the awards ceremony, or a business conference, including managing ticket sales, creating presentation schedules, and seating plans.</p>
            <p><b>Seating plans</b></p>
            <p>Seating plan feature allows you to create seating areas with varying capacities, whether it's large sections for general admission or smaller, intimate areas for VIP guests.</p>
            <p>Our software enables you to customize the size and layout of each seating area, ensuring optimal space utilization and a comfortable experience for attendees.</p>
            <img src={seating} alt="" width='100%'/>
            <p>In addition, our software incorporates tags, which can be assigned to seats or groups of seats, allowing you to create tables or designated areas for specific groups. This is particularly useful for events where attendees are part of a larger party or organization, as it helps ensure they are seated together. </p>
            <p>By using tags to create tables and groups, you can enhance the social and networking aspects of your event, fostering a sense of community among attendees.</p>
            </>
        )
    },
    {   
        name: "Benchmark reports",
        content: (
            <>
            <p><b>More benefits to your participants</b></p>
            <p>Unique about our awards management software is most definitely a robust benchmark report feature that provides valuable insights and comparative analysis to enhance the overall benefit to your customers.</p>
            <img src={benchmark} alt="" width='100%'/>
            <p><b>Benchmark against competition</b></p>
            <p>This comprehensive report showcases key performance metrics, trends, and benchmarks across different award categories. It allows participants to benchmark against predefined criteria or industry standards and competitors in their field of business. </p>
            <p><b>Qualitative feedback</b></p>
            <p>The report feature goes beyond performance metrics and includes valuable insights from judges through their comments. By including judges' comments in the benchmark report, your customers gain deeper insights into the strengths, weaknesses, and unique aspects of each submission. It also allows your customers to review the qualitative feedback provided by judges, helping them identify specific areas of excellence or improvement for their initiative.</p>
            </>
        )
    }
];


function FeaturesAccordion1() {

    const [active, setActive] = useState(0);

    function toggleAccordion(index) {
        setActive(index);
}



return (
    <div className='features-accordion'>
        <div className="features-accordion-heading">
        {accordion.map((item, index) => 
           <h3 className={`features-accordion-screen ${active === index ? "active" : ""}`} key={index} onClick={() => toggleAccordion(index)}>{item.name}</h3>
        )}    
        </div>
        
        <div className="features-accordion-content">
        {accordion.map((item, index) => 
            <div className='features-accordion-info' key={index}>
            <div className={active === index ? "active" : "inactive"}>
            <div className="close-btn" onClick={() => toggleAccordion(null)}><FaTimes /></div>
                <p>{item.content}</p>
            </div>
            </div>
        )}  
        </div>
    </div>
);
}


//     function FeaturesAccordion() {

//         const [active, setActive] = useState(0);

//         function toggleAccordion(index) {
//             setActive(index);
//     }

    

//     return (
//         <div className='features-accordion'>
//             {accordion.map((item, index) => 
//             <div className='features-accordion-box'>
//                 <div>
//                     <h3 className={`features-accordion-screen ${active === index ? "active" : ""}`} key={index} onClick={() => toggleAccordion(index)}>{item.name}</h3>
//                 </div>
//                 <div className='features-accordion-info'>
//                 <div className={active === index ? "active" : "inactive"}>
//                     <p>{item.content}</p>
//                 </div>
//                 </div>
//             </div>
//             )}    
//         </div>
//     );
// }



function Features() {
    return (
        <>
        <Helmet>
            <title>AwardMost &nbsp; - &nbsp; Features </title>
            <meta name="description" content="" />
        </Helmet>
        <ScrollToTop />
        <div className="content">
        <div className="features-heading">
            <div className="main-wrapper">
                <h1>Features</h1>
                <p>All awards and events features available to you to give the best experience to all participants and sell most entries, judges and tickets.</p>
            </div>
        </div>
        
        <div className="features-tabs">
            <div className="main-wrapper">
                <h2>All Features</h2>
                <FeaturesAccordion1 />
            </div>
        </div>
        
        </div>
        </>
    )
}


export default Features;