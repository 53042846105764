import UseCasesDownload from "../components/UseCasesDownload";
import icxa from '../img/icxa-case-study-cover.png';
import '../css/UseCases.css';
import { Helmet } from "react-helmet";

function IcxaCaseStudy() {
    return (
        <>
        <Helmet>
            <title>AwardMost &nbsp; - &nbsp; ICXA Case Study </title>
            <meta name="description" content="" />
        </Helmet>
        <div className="use-cases-page">
        <div className="main-wrapper">
            <h1 className="text-center">Case study</h1>
        

        
        <UseCasesDownload 
            image = {icxa}
            heading = "International Customer Experience Awards"
            info = "Full online awards. From start to ﬁnish held live online. The awards ceremony broadcasted from a TV studio."
            action = "https://forms.zohopublic.com/awardsinternational/form/AwardmostCaseStudy2/formperma/08og-vs7hFBA66s88N_6OqHLcP-zwTFrXG-u22wnNY4/htmlRecords/submit"
        />
        </div>
        </div>
        </>
    )
}


export default IcxaCaseStudy;