function Author(author) {
    return (
        <>
        <img src={author.img} alt="" />
        <p>{author.name}</p>
        <p>{author.job}</p>
        </>
    )
}

export default Author;