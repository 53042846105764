import { Link } from "react-router-dom";
import cxalogo from '../img/CXA-min-logo.png';
import balogo from '../img/UKBA22-logo-ai.png';

function DropDownMenu() {
    return (
        <ul className="dropdown-menu">
            <li><Link to="/cxa-case-study"><img src={cxalogo} alt /> UKCXA 2022</Link></li>
            <li><Link to="/icxa-case-study"><img src={cxalogo} alt /> iCXA 2022</Link></li>
            <li><Link to="/ba-case-study"><img src={balogo} alt /> UKBA 2022</Link></li>
        </ul>
    )
}

export default DropDownMenu;