import Welcome from '../home/Welcome';
import Features from '../home/Features';
import Testimonials from '../home/Testimonials';
import Experience from '../home/Experience';
import Contact from '../home/ContactUs';
import Cases from '../home/Cases';
import AwardModels from '../home/AwardModels';

function Home() {
    return (
    <>
    <div className='content'>
      <Welcome />
      <Features />
      <Testimonials />
      <Experience />
      <AwardModels />
      <Cases />
      <Contact />
      </div>
    </>
    )
}


export default Home;