import { useState } from 'react';
import Modal from "../components/Modal";
import { Helmet } from 'react-helmet';

function Button() {
    const [isOpen, setIsOpen] = useState(false);

      return (
        <>
          <button onClick={() => setIsOpen(true)}>
          Book appointment       
          </button>
        {isOpen && <Modal setIsOpen={setIsOpen} />}
        </>
      );
}



function ThankYou() {
    return (
      <>
      <Helmet>
        <title>AwardMost &nbsp; - &nbsp; Thank You </title>
        <meta name="description" content="" />
      </Helmet>
        <div className="thank-you">
            <div className="main-wrapper">
                <div className="thank-you-center">
                    <h1>Thank you for reaching out!</h1>
                    <p>We greatly appreciate your interest. Rest assured, a dedicated member of our team will be in touch with you within the next 24 hours to discuss your specific requirements in detail.</p>
                    <p>Alternatively, if you prefer to schedule a call at your convenience, we invite you to utilize the calendar provided on the click of the Book appointment button. We look forward to connecting with you and addressing your needs promptly.</p>
                    <Button />
            </div>
            </div>
        </div>
        </>
    )
}

export default ThankYou;