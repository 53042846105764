import ScrollToTop from "..";
import { Helmet } from "react-helmet";

function TermsAndCondtiions() {
    return (
        <>
        <Helmet>
            <title>AwardMost &nbsp; - &nbsp; Terms and Conditions </title>
            <meta name="description" content="" />
        </Helmet>
        <ScrollToTop />
        <div className="tc">
        <div className="main-wrapper-tc">
            <h1>Terms and Conditions</h1>

            <p>These terms and conditions ("Terms") govern your use of the AwardMost website ("Website") provided by The Most International ltd ("Company," "we," or "us"). By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using the Website.</p>

            <p><b>1. Use of the Website:</b></p>
            <p>1.1. You are responsible for maintaining the confidentiality of your account information and password. Any activities that occur under your account are your sole responsibility. <br/>
            1.2. You agree not to use the Website for any unlawful or unauthorized purposes. You shall comply with all applicable laws and regulations while using the Website.
            </p>

            <p><b>2. Intellectual Property:</b></p>

            <p>
            2.1. The content on the Website, including text, graphics, logos, images, and software, is the property of the Company and protected by copyright and other intellectual property laws. <br/>
            2.2. You may not modify, reproduce, distribute, transmit, display, perform, or create derivative works from any content on the Website without prior written permission from the Company.
            </p>

            <p><b>3. Privacy Policy:</b></p>

            <p>
            3.1. We respect your privacy and handle your personal information in accordance with our Privacy Policy. By using the Website, you consent to our collection, use, and disclosure of personal information as outlined in the Privacy Policy.
            </p>

            <p><b>4. Disclaimer of Warranties:</b></p>

            <p>
            4.1. The Website is provided on an "as is" and "as available" basis, without any warranties, express or implied. We do not warrant that the Website will be uninterrupted, error-free, or free of viruses or other harmful components. <br/>
            4.2. We disclaim all warranties, including but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.
            </p>


            <p><b>5. Limitation of Liability:</b></p>

            <p>
                5.1. In no event shall the Company or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or concerning the use of the Website. <br/>
                5.2. You agree to indemnify and hold the Company and its affiliates harmless from any claims, damages, or losses arising out of your use of the Website or violation of these Terms.
            </p>


            <p><b>6. Third-Party Websites and Content:</b></p>

            <p>
            6.1. The Website may contain links to third-party websites or resources. We are not responsible for the availability, accuracy, or content of such websites or resources. Accessing and using third-party websites is at your own risk.
            </p>

            <p><b>7. Modifications to the Terms:</b></p>

            <p>
                7.1. We reserve the right to modify or update these Terms at any time without prior notice. Your continued use of the Website after any changes to the Terms constitutes acceptance of the modified Terms.
            </p>

            <p><b>8. Governing Law and Jurisdiction:</b></p>

            <p>
            8.1. These Terms shall be governed by and construed in accordance with the laws of the United Kingdom. Any disputes arising under or concerning these Terms shall be subject to the exclusive jurisdiction of the courts of the United Kingdom.
            </p>

            <p><b>9. Severability:</b></p>

            <p>
            9.1. If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
            </p>

            <p><b>10. Contact Information:</b></p>

            <p>
            10.1. If you have any questions or concerns regarding these Terms, please contact us at  <a href="mailto:info@awardsmost.com">info@awardmost.com</a>.
            </p>

            <p>
            By using the AwardMost website, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
            </p>
        </div>
        </div>
        </>
    )
}

export default TermsAndCondtiions;