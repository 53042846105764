import ScrollToTop from "..";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
    return (
        <>
        <Helmet>
            <title>AwardMost &nbsp; - &nbsp; Privacy Policy </title>
            <meta name="description" content="" />
        </Helmet>
        <ScrollToTop />
        <div className="tc">
        <div className="main-wrapper-tc">
            <h1>Privacy Policy</h1>

            <p>This Privacy Policy ("Policy") outlines how The Most International Ltd ("Company," "we," or "us") collects, uses, and protects your personal information when you use the AwardMost website ("Website"). By accessing or using the Website, you agree to the terms of this Policy. If you do not agree with this Policy, please refrain from using the Website.</p>

            <p><b>1. Information We Collect:</b></p>
            <p>1.1. Personal Information: We may collect personal information, such as your name, email address, contact information, and any other information you voluntarily provide to us.<br/>
            1.2. Usage Data: We may collect non-personal information about your interactions with the Website, such as your IP address, browser type, device information, and browsing activities.
            </p>

            <p><b>2. Use of Collected Information:</b></p>

            <p>
            2.1. We use the collected information for the following purposes:
            </p>
            <ul style={{listStyleType: 'lower-alpha'}}>
                <li>To provide and maintain the Website and its features.</li>
                <li>To personalise and enhance your experience on the Website.</li>
                <li>To respond to your inquiries and provide customer support.</li>
                <li>To send you promotional and marketing communications, if you have opted to receive them.</li>
                <li>To analyse usage patterns, diagnose technical issues, and improve the functionality and performance of the Website.</li>
            </ul>

            <p><b>3. Data Sharing and Disclosure:</b></p>

            <p>
            3.1. We may share your personal information with trusted third-party service providers who assist us in operating the Website and providing services to you. These service providers are bound by confidentiality agreements and are not permitted to use your personal information for any other purpose. <br/>
            3.2. We may also disclose your personal information if required by law or when we believe such disclosure is necessary to protect our rights, comply with a legal obligation, or safeguard the safety of users.
            </p>

            <p><b>4. Data Retention:</b></p>

            <p>
            4.1. We retain your personal information for as long as necessary to fulfil the purposes outlined in this Policy, unless a longer retention period is required or permitted by law.
            </p>


            <p><b>5. Security Measures:</b></p>

            <p>
            5.1. We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            </p>


            <p><b>6. Third-Party Websites:</b></p>

            <p>
            6.1. The Website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of those third-party websites before providing any personal information.
            </p>

            <p><b>7. Children's Privacy:</b></p>

            <p>
            7.1. The Website is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If you believe we have inadvertently collected personal information from a child, please contact us, and we will take appropriate steps to remove the information from our records.
            </p>

            <p><b>8. Changes to the Privacy Policy:</b></p>

            <p>
            8.1. We reserve the right to modify or update this Privacy Policy at any time. We will notify you of any material changes by posting the updated Policy on the Website. Your continued use of the Website after any modifications to this Policy constitutes acceptance of the revised Policy.
            </p>

            <p><b>9. Contact Information:</b></p>

            <p>
            9.1. If you have any questions or concerns regarding this Privacy Policy or the handling of your personal information, please contact us at <a href="mailto:info@awardmost.com">info@awardmost.com</a>.
            </p>

            <p>
            By using the AwardMost website, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.
            </p>
        </div>
        </div>
        </>
    )
}

export default PrivacyPolicy;