import '../css/Modal.css';
import { RiCloseLine } from "react-icons/ri";
import GetDemoFormHome from './get-demo-home';

const Modal = ({ setIsOpen }) => {
    return (
      <>
        <div className='darkBG' onClick={() => setIsOpen(false)} />
        <div  className='centered'>
         {/* <div id='hideMe'><h3>Loading the calendar</h3></div>  */}
          <div className='modal'>
            {/* <div className='modalHeader'>
              <h5 className='heading'>Book a Demo Today</h5>
            </div> */}
            <span className='closeBtn' onClick={() => setIsOpen(false)}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </span>
            <div className='modalContent'>
              <GetDemoFormHome />
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default Modal;


