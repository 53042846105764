function GetDemoFormHome() {

  const head = document.querySelector('head');
  const script = document.createElement('script');
  script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
  head.appendChild(script);

    return (
     
      <>
      <div class="calendly-inline-widget" data-url="https://calendly.com/info-udy/product-demonstration?hide_event_type_details=1&hide_gdpr_banner=1&text_color=002255&primary_color=5fd38d" style={{minWidth: '320px', height: '700px'}}></div>
      </>

      //   <iframe width='100%' height='750px' src='https://awardsinternational.zohobookings.com/portal-embed#/customer/3975865000003141032' frameborder='0' allowfullscreen='' > </iframe> 

        // <div className="contact-us">
        // <div className="contact-us-form text-center">
        // <form action='https://forms.zohopublic.com/awardsinternational/form/AwardmostGetaDemo/formperma/3XoPY3CUsFek2MllXmFtgTD8mWJRgSFdaZhSpakO3Kw/htmlRecords/submit'name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data'>
        //         <input type="hidden" name="zf_referrer_name" value="" />
        //         <input type="hidden" name="zf_redirect_url" value="" />
        //         <input type="hidden" name="zc_gad" value="" />
        //         <input type="text" maxlength="255" name="Name_First" fieldType='7' placeholder="First Name" />
        //         <br/>
        //         <input type="text" maxlength="255" name="Name_Last" fieldType='7' placeholder="Last Name" />
        //         <br/>
        //         <input type="text" maxlength="255" name="Email" fieldType='9' placeholder="Email" />
        //         <br/>
        //         <input  type="text" name="SingleLine"  fieldType='1' maxlength="255" placeholder="Company" />
        //         <br/>
                
        //         <button type="submit">Submit</button>
        // </form>
        // </div>
        // </div>
    )
}


export default GetDemoFormHome;