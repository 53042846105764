import { Link } from "react-router-dom";

function NotFound() {
    return (
        <div className="not-found">
            <div className="main-wrapper">
                <h1>404</h1>
                <p> Oops! We couldn't find that page.</p>
                <p>You may wish to go back to the <Link to='/'>Home</Link>.</p>
            </div>
        </div>
    )
}


export default NotFound;