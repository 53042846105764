import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Solutions from './pages/Solutions';
import Pricing from './pages/Pricing';
import Features from './pages/Features';
import CxaCaseStudy from './pages/CxaCaseStudy';
import BaCaseStudy from './pages/BaCaseStudy';
import IcxaCaseStudy from './pages/IcxaCaseStudy';
import TermsAndCondtiions from './pages/TC';
import ThankYou from './pages/ThankYou';
import ConsultantThankYou from './pages/ConsultantThankYou';
import Blog from './pages/Blog';
import Test from './single-blog/Test';
import ValueOfJudging from './single-blog/ValueOfJudging';
import MaxRoi from './single-blog/MaxRoi';
import SevenSteps from './single-blog/SevenSteps';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AwardsPlatform from './pages/AwardsPlatform';
import NotFound from './pages/404';

 function App() {
   return (
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/solutions' element={<Solutions />}/>
        <Route path='/features' element={<Features />}/>
        <Route path='/pricing' element={<Pricing />}/>
        <Route path='/cxa-case-study' element={<CxaCaseStudy />}/>
        <Route path='/ba-case-study' element={<BaCaseStudy />}/>
        <Route path='/icxa-case-study' element={<IcxaCaseStudy />}/>
        <Route path='/terms-and-conditions' element={<TermsAndCondtiions />}/>
        <Route path='/thank-you' element={<ThankYou />}/>
        <Route path='/blog' element={<Blog />}/>
        <Route path='/awards-software-consultations' element={<AwardsPlatform />}/>
        <Route path='/blog/guide-to-an-effective-awards-programme' element={<Test />}/>
        <Route path='/blog/how-to-provide-extra-value-to-your-judges' element={<ValueOfJudging />}/>
        <Route path='/blog/maximizing-the-roi-on-entering-an-awards-programme' element={<MaxRoi />}/>
        <Route path='/blog/building-an-effective-entry-form-7-steps-for-success' element={<SevenSteps />}/>
        <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
        <Route path='/book-consultations-thank-you' element={<ConsultantThankYou />}/>
        <Route path='*' element={<NotFound />}/>
      </Routes>
   )
}

export default App;
