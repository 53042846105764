// import { useState } from 'react';
// import Modal from "../components/Modal";
import { Helmet } from 'react-helmet';
import Cases from '../home/Cases';
import ScrollToTop from '..';

// function Button() {
//     const [isOpen, setIsOpen] = useState(false);

//       return (
//         <>
//           <button onClick={() => setIsOpen(true)}>
//           Book appointment       
//           </button>
//         {isOpen && <Modal setIsOpen={setIsOpen} />}
//         </>
//       );
// }



function ConsultantThankYou() {
    return (
      <>
      <Helmet>
        <title>AwardMost &nbsp; - &nbsp; Thank You </title>
        <meta name="description" content="" />
      </Helmet>
      <ScrollToTop />
        <div className="consultant-thank-you">
            <div className="main-wrapper">
                <div className="consultant-thank-you-center">
                    <h1>Thank you for reaching out!</h1>
                    <p>We greatly appreciate your interest. Rest assured, a dedicated member of our team will be in touch with you within the next 24 hours to discuss your specific requirements in detail.</p>
                    <p>If you'd like to explore our success case studies, choose from the links below.</p>
                    <Cases />
            </div>
            </div>
        </div>
        </>
    )
}

export default ConsultantThankYou;