import UseCasesDownload from "../components/UseCasesDownload";
import ba from '../img/ukba-case-study-cover.png';
import '../css/UseCases.css';
import { Helmet } from "react-helmet";

function BaCaseStudy() {
    return (
        <>
        <Helmet>
            <title>AwardMost &nbsp; - &nbsp; UKBA Case Study </title>
            <meta name="description" content="" />
        </Helmet>
        <div className="use-cases-page">
        <div className="main-wrapper">
            <h1 className="text-center">Case study</h1>
        

        
        <UseCasesDownload 
            image = {ba}
            heading = "UK Business Awards"
            info = "Smart awards. Quick and simple awards programme with one scoring round best suited for SMEs."
            action = "https://forms.zohopublic.com/awardsinternational/form/AwardmostCaseStudy3/formperma/sYDkU_IhaqAu6IZTzzZm1_3_fGWySHXUOq767Nxclsg/htmlRecords/submit"
        />
        </div>
        </div>
        </>
    )
}


export default BaCaseStudy;