function SubForm() {
    return (

      <>
      <script type="text/javascript" src="https://campaigns.zoho.com/js/zc.iframe.js"></script>
      <iframe frameborder="0" className="sub-frame" id="iframewin" width="100%" height="100%" src="https://kpuo-cmpzourl.maillist-manage.com/ua/Optin?od=11287eca6b8101&zx=12d8f0554&tD=129e0b9c3b2ae0bf&sD=129e0b9c3b2ae0bb"></iframe>

      </>
    )
} 


export default SubForm;