import ScrollToTop from "..";

function UseCasesDownload(props) {
    return (
        <>
        <ScrollToTop />
        <div className="use-cases">
            <div className="use-cases-img">
                <img src={props.image}  alt=""/>
            </div>
            <div className="use-cases-form">
                <h3>{props.heading}</h3>
                <p><b>{props.info}</b></p>

                <form action={props.action} name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data'>
                    <input type="hidden" name="zf_referrer_name" value="" />
                    <input type="hidden" name="zf_redirect_url" value="" />
                    <input type="hidden" name="zc_gad" value="" />
                    <input type="text" maxlength="255" name="Name_First" fieldType='7' placeholder="First Name" required/>
                    <br/>
                    <input type="text" maxlength="255" name="Name_Last" fieldType='7' placeholder="Last Name" required/>
                    <br/>
                    <input type="text" maxlength="255" name="Email" fieldType='9' placeholder="Email" required/>
                    <br/>
                    <input  type="text" name="SingleLine"  fieldType='1' maxlength="255" placeholder="Company"  required/>
                    <br/>
                    
                    <div className="use-cases-button">
                        <button type="submit">Download</button>
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}

export default UseCasesDownload;